<template>
  <div class="page-content-configuration">
    <div class="menu" :class="{ 'menu-immo ': getCurentProjectTypeImmo }">
      <div class="title-menu">
        <div
          class="font-sz-16 bold-700 font-text color-crm-bold text-uppercase"
        >
          {{ $t('parametres') }}
        </div>
      </div>
      <v-divider></v-divider>
      <div class="menu-links">
        <div class="menu-title">{{ $t('gestionEntite') }}</div>
        <router-link
          v-if="(currentUser && currentUser.isSuper) || computedRegie"
          class="menu-item"
          to="/setting/users"
        >
          {{ $t('users') }}
        </router-link>
        <template v-if="currentUser && currentUser.isSuper">
          <router-link class="menu-item" to="/setting/historique-users">
            {{ $t('historyUsers') }}
          </router-link>
          <router-link class="menu-item" to="/setting/groupe">
            {{ $t('group') }}
          </router-link>
          <router-link class="menu-item" to="/setting/teams">
            {{ $t('equipe') }}
          </router-link>
          <!-- <router-link class="menu-item" to="/setting/deposits">
            {{ $t('depot') }}
          </router-link> -->
          <router-link class="menu-item" to="/setting/visibility">
            {{ $t('visibility') }}
          </router-link>
          <router-link class="menu-item" to="/setting/connection">
            {{ $t('connexions') }}
          </router-link>
        </template>
      </div>
      <v-divider v-if="currentUser && currentUser.isSuper"></v-divider>
      <div class="menu-links" v-if="currentUser && currentUser.isSuper">
        <div class="menu-title">{{ $t('gestionProjet') }}</div>
        <template>
          <router-link class="menu-item" to="/setting/projects-types">
            {{ $t('typesProjets') }}
          </router-link>
          <router-link class="menu-item" to="/setting/champs-personnalises">
            {{ $t('customFields') }}
          </router-link>
          <router-link class="menu-item" to="/setting/categorie">
            {{ $t('categories') }}
          </router-link>
          <router-link class="menu-item" to="/setting/pipeline">
            {{ $t('pipelines') }}
          </router-link>
          <router-link class="menu-item" to="/setting/resource">
            {{ $t('resources') }}
          </router-link>
          <router-link class="menu-item" to="/setting/type-evenements">
            {{ $t('evenement') }}
          </router-link>
          <router-link class="menu-item" to="/setting/sources">
            {{ $t('sources') }}
          </router-link>
          <!-- <router-link class="menu-item" to="/setting/precarite">
            {{ $t('precarite') }}
          </router-link> -->
          <router-link class="menu-item" to="/setting/visibility-project">
            {{ $t('visibilityProjet') }}
          </router-link>
          <router-link class="menu-item" to="/setting/templates">
            {{ $t('template') }}
          </router-link>
          <router-link class="menu-item" to="/setting/webhook">
            {{ $t('webhooks') }}
          </router-link>
          <router-link class="menu-item" to="/setting/api">
            {{ $t('apiToken') }}
          </router-link>
        </template>
      </div>
      <v-divider v-if="currentUser && currentUser.isSuper"></v-divider>
      <div class="menu-links" v-if="currentUser && currentUser.isSuper">
        <div class="menu-title">{{ $t('history') }}</div>
        <router-link class="menu-item" to="/setting/history-export-excel">
          {{ $t('history') }} {{ $t('exportExcel') }}
        </router-link>
        <router-link class="menu-item" to="/setting/history-planning">
          {{ $t('history') }} {{ $t('planning') }}
        </router-link>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Admin',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['currentUser', 'getCurentProjectTypeImmo']),
    computedRegie: function() {
      return (
        this.currentUser &&
        (this.currentUser.type == 'user.commercial' ||
          this.currentUser.type == 'user.final' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type === 'regie'))
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content-configuration {
  position: relative;
  //   padding: 16px;
  height: 100%;
  width: 100%;
  //   max-width: 1300px;
  //   margin: 0 auto;
  .menu::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }
  .menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #80808054;
    border-radius: 10px;
  }
  .menu::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }
  .menu::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
  .menu {
    cursor: pointer;
    white-space: normal;
    max-height: 100%;
    // height: 94%;
    max-height: calc(100vh - 141px) !important;
    height: calc(100vh - 141px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f6f6f6;
  }
  .menu-immo {
    max-height: calc(100vh - 115px) !important;
    height: calc(100vh - 115px) !important;
  }

  .menu {
    position: absolute;
    width: 263px;
    .title-menu {
      padding: 30px 23px 15px 23px;
    }
    .menu-links {
      padding: 10px 23px 10px 23px;
      font-weight: 600;
      font-size: 13px;
      .menu-title {
        margin: 5px 0;
        font-size: 14px;
        text-transform: uppercase;
        color: #000;
        font-weight: 700;
        letter-spacing: -0.41px;
        font-family: 'Montserrat', sans-serif;
      }

      .menu-item {
        display: block;
        margin: 4px 0px;
        padding: 6px 0px;
        text-decoration: none;
        color: #000;
        font-weight: 400;
        letter-spacing: -0.41px;
        font-family: 'Montserrat', sans-serif;
        &:hover {
          color: #5c2dd3;
          font-weight: 600;
        }
        &.router-link-active {
          color: #5c2dd3;
          font-weight: 600;
        }
      }
      .menu-item::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .content {
    display: block;
    padding: 20px;
    height: 100%;
    margin-left: 263px;
    overflow: hidden;
    background-color: #fff;
    box-sizing: border-box;
  }
  .disabled {
    pointer-events: none;
  }
}
</style>
